
import { Options, Vue } from 'vue-class-component'
import login from '@/components/Memberportal/Login/index.vue'

@Options({
  components: {
    login
  }
})
export default class Login extends Vue {}
